

































import { computed, defineComponent, useRoute } from '~/utils/nuxt3-migration'

export default defineComponent({
  setup() {
    const route = useRoute()
    const homeRoute = computed(() =>
      route.value.name === '__index' ? null : '/'
    )
    return {
      homeRoute
    }
  }
})
