import { useNamespacedStore } from '~/compositions/store'
import { computed } from '~/utils/nuxt3-migration'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'

export function useUser() {
  const { state: userState } = useNamespacedStore<UserState>(USER_NS)

  const subscription = computed(() => userState.subscription)
  const perks = computed(() => userState.perks)

  const subscriptionExpiresSoon = computed<boolean>(() => {
    const expiresInDays = subscription.value?.expiresInDays
    if ((!expiresInDays && expiresInDays !== 0) || expiresInDays < 0) {
      return false
    }

    const expiresToday = expiresInDays === 0 && !subscription.value?.expired

    return expiresToday || expiresInDays <= 10
  })

  const noSubscription = computed<boolean>(() => subscription.value === null)

  const subscriptionExpired = computed<boolean>(() =>
    Boolean(subscription.value?.expired)
  )

  const hasActivePaidPerks = computed(() => {
    return perks.value?.tier
      ? ['dealer_silver', 'dealer_gold'].includes(perks.value.tier)
      : false
  })

  return {
    subscriptionExpiresSoon,
    subscription,
    subscriptionExpired,
    noSubscription,
    hasActivePaidPerks
  }
}
