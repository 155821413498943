










































































































































































































































































































































































































































































import { mapState } from 'vuex'
import Category from '~/components/car/classifieds/search/results/rows/main/partials/Category.vue'
import Price from '~/components/car/classifieds/search/results/rows/main/partials/Price.vue'
import PricePerUnit from '~/components/car/classifieds/search/results/rows/main/partials/PricePerUnit.vue'
import Title from '~/components/car/classifieds/search/results/rows/main/partials/Title.vue'
import RowMixin from '~/components/car/classifieds/search/results/rows/main/RowMixin.vue'
import CHybridTelephone from '~/components/shared/configurable/telephone/CHybridTelephone.vue'
import {
  adminTimeDistanceFromNow,
  cappedTimeDistanceFromNow
} from '~/utils/date'
import InstallmentPrice from './partials/InstallmentPrice.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import CClassifiedThumbnail from '~/components/shared/configurable/classified/image/CClassifiedThumbnail.vue'
import Description from '~/components/car/classifieds/search/results/rows/main/partials/Description.vue'
import CClassifiedCompareButton from '~/components/shared/configurable/classified/compare/CClassifiedCompareButton.vue'
import DiscountPercentageTag from '~/components/car/classifieds/DiscountPercentageTag.vue'
import ClassifiedSearchResultsRowPromoRibbon from '~/components/car/classifieds/search/results/ClassifiedSearchResultsRowPromoRibbon.vue'
import PromoUser from '~/components/car/classifieds/search/results/rows/main/partials/PromoUser.vue'
import KeyFeaturesAndCategoriesMobile from '~/components/car/classifieds/search/results/rows/main/KeyFeaturesAndCategoriesMobile.vue'
import InstallmentsBadge from '~/components/car/classifieds/badges/InstallmentsBadge.vue'
import CBadge360 from '~/components/shared/configurable/badge/CBadge360.vue'
import {
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'
import MarketplaceRibbon from '~/components/car/classifieds/search/results/rows/main/partials/MarketplaceRibbon.vue'
import MarketplaceProductAvailabilityBadge from '~/components/car/marketplace/product/MarketplaceProductAvailabilityBadge.vue'
import { ciClock } from '~/icons/source/solid/clock'
import { ciStar } from '~/icons/source/solid/star'
import { ciEnvelope } from '~/icons/source/solid/envelope'
import { ciTelephone } from '~/icons/source/solid/telephone'
import { ciLocationPin } from '~/icons/source/solid/location-pin'

export default defineComponent({
  components: {
    MarketplaceProductAvailabilityBadge,
    MarketplaceRibbon,
    CBadge360,
    InstallmentsBadge,
    KeyFeaturesAndCategoriesMobile,
    PromoUser,
    BackgroundJobsBadge: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/BackgroundJobs/BackgroundJobsBadge.vue'
      )
    ),
    PaidClassifiedInfo: defineVue3AsyncComponent(() =>
      import('./partials/PaidInfo/PaidClassifiedInfo.vue')
    ),
    ClassifiedSearchResultsRowPromoRibbon,
    CClassifiedThumbnail,
    Title,
    Category,
    Description,
    Price,
    DiscountPercentageTag,
    PricePerUnit,
    CHybridTelephone,
    CClassifiedCompareButton,
    InstallmentPrice
  },
  mixins: [RowMixin],
  computed: {
    ...mapState(USER_NS, {
      userType: 'type'
    }),
    lastModifiedStr() {
      if (this.userIsAdmin) {
        return adminTimeDistanceFromNow(this.classified.modified)
      }
      return cappedTimeDistanceFromNow(this.classified.modified, 60, {
        locale: this.$i18n.locale,
        addPrefix: false
      })
    },
    icons() {
      return {
        mapMarker: ciLocationPin,
        clock: ciClock,
        phone: ciTelephone,
        envelope: ciEnvelope,
        star: ciStar
      }
    }
  }
})
