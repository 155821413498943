
















import {
  computed,
  defineNuxtComponent,
  PropType
} from '~/utils/nuxt3-migration'

export default defineNuxtComponent({
  inheritAttrs: true,
  props: {
    variant: {
      type: String as PropType<'primary' | 'secondary' | 'neutral'>,
      default: 'primary'
    },
    icon: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, attrs }) {
    const isLink = computed(() => {
      return attrs.href || attrs.to
    })
    const itemVariantClasses = computed(() => {
      switch (props.variant) {
        case 'primary':
          return 'tw-text-grey-900 tw-bg-white tw-font-medium hover:tw-text-primary hover:tw-bg-grey-150 focus-visible:tw-text-primary focus-visible:tw-bg-grey-150 aria-selected:tw-text-white aria-selected:tw-bg-primary'
        case 'secondary':
          return 'tw-text-grey-900 tw-bg-white tw-font-medium hover:tw-text-blue-500 hover:tw-bg-grey-150 focus-visible:tw-text-blue-500 focus-visible:tw-bg-grey-150 aria-selected:tw-text-white aria-selected:tw-bg-blue-500'
        case 'neutral':
          return 'tw-text-grey-900 tw-bg-white tw-font-medium hover:tw-text-grey-900 hover:tw-bg-grey-150 focus-visible:tw-text-grey-900 focus-visible:tw-bg-grey-150 aria-selected:tw-text-white aria-selected:tw-bg-grey-700'
      }
    })

    function handleClick(e: Event) {
      emit('click', e)
    }

    return {
      isLink,
      itemVariantClasses,
      handleClick
    }
  }
})
