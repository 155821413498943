import { CIcon } from '~/icons/types'

export const ciTelephone: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M23.981 18.135c-.16-.748-.443-1.345-.863-1.83-.849-.978-1.844-1.688-2.962-2.116-1.23-.47-2.442-.381-3.603.273-.726.409-1.32.95-1.75 1.378-.098.099-.17.117-.315.08-.51-.127-.944-.428-1.297-.706a24.771 24.771 0 0 1-4.504-4.59c-.363-.475-.566-.866-.67-1.256-.061-.235.005-.32.085-.395.203-.188.382-.39.538-.57 1.004-1.17 1.245-2.525.698-3.922-.505-1.284-1.302-2.455-2.382-3.49A3.598 3.598 0 0 0 5.603.16C4.579-.175 3.579.018 2.712.73 2.032 1.284 1.42 1.89.75 2.568c-.26.264-.396.612-.505.894-.08.211-.122.418-.165.616-.019.08-.033.155-.052.226l-.01.056L0 5.403l.01.043c.023.14.047.286.065.423.048.32.1.649.17.973.359 1.585 1.014 3.152 2.01 4.784 2.499 4.11 5.786 7.44 9.77 9.895 1.699 1.045 3.326 1.712 4.972 2.037.443.09.966.17 1.528.17.462 0 .952-.057 1.448-.208.32-.098.575-.22.778-.366.99-.73 1.716-1.407 2.287-2.14a4.813 4.813 0 0 0 .938-2l.01-.046.014-.781-.019-.052ZM12.696 1.913c4.734 0 8.592 3.843 8.592 8.57a.494.494 0 0 0 .99 0C22.279 5.215 17.983.93 12.7.93a.494.494 0 0 0-.495.494c0 .273.217.49.49.49Z'
    },
    {
      d:
        'M11.91 6.052c2.88 0 5.225 2.338 5.225 5.212a.495.495 0 0 0 .99 0c0-3.42-2.787-6.2-6.216-6.2a.494.494 0 1 0 0 .988Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'telephone',
  type: 'solid'
}
