import { CIcon } from '~/icons/types'

export const ciCompare: CIcon = {
  width: '18',
  height: '18',
  paths: [
    {
      d:
        'M8.666 9.333c-.083-.083-.25-.25-.416-.25C8.083 9 7.917 9 7.75 9H4.167c-.667 0-1.25.583-1.25 1.25s.583 1.25 1.25 1.25h.583L.667 15.583c-.5.5-.5 1.25 0 1.75.25.25.583.334.916.334.334 0 .667-.084.917-.334l4-4.083v.583c0 .667.583 1.25 1.25 1.25.666 0 1.25-.583 1.25-1.25V10.25c0-.167 0-.333-.084-.5-.083-.083-.166-.25-.25-.417ZM17.417.584c-.5-.5-1.25-.5-1.75 0L11.5 4.751v-.584c0-.666-.583-1.25-1.25-1.25S9 3.501 9 4.167v3.584c0 .166 0 .333.083.5.084.166.167.25.25.416.084.084.25.25.417.25.167.084.333.084.5.084h3.583c.667 0 1.25-.584 1.25-1.25 0-.667-.5-1.25-1.25-1.25h-.583l4.083-4.084c.5-.5.5-1.333.084-1.833Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'compare',
  type: 'solid'
}
