

























import { defineComponent, ref } from '~/utils/nuxt3-migration'
import { useConsent } from '~/compositions/consent'

export default defineComponent({
  setup() {
    const loading = ref(false)
    const { confirmOver18 } = useConsent()

    async function onButtonClick() {
      loading.value = true
      await confirmOver18()
      loading.value = false
    }

    return { onButtonClick, loading }
  }
})
