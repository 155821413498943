import { ref } from '~/utils/nuxt3-migration'

// Focusable selector matching our v-c-focus directive
const focusableElements = [
  'a[href]',
  'area[href]',
  'button:not([disabled]):not([aria-hidden])',
  'input:not([disabled]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex="-1"]):not([disabled]):not([aria-hidden])'
].join(',')

/**
 * Composition function for focus management
 */
export default function useFocus() {
  const lastFocusedElement = ref<HTMLElement | null>(null)

  /**
   * Focus an element
   */
  function focus(element: HTMLElement): void {
    if (element && typeof element.focus === 'function') {
      setTimeout(() => element.focus(), 0)
    }
  }

  /**
   * Get all focusable elements within a container
   */
  function getFocusableElements(container: HTMLElement): HTMLElement[] {
    if (!container) return []

    const candidates = Array.from(
      container.querySelectorAll(focusableElements)
    ) as HTMLElement[]

    // Filter out hidden elements
    return candidates.filter(el => {
      if (!el.offsetWidth && !el.offsetHeight) return false

      // Check for visibility and display
      const style = window.getComputedStyle(el)
      return !(style.visibility === 'hidden' || style.display === 'none')
    })
  }

  /**
   * Focus the first focusable element in a container
   */
  function focusFirst(container: HTMLElement): void {
    const elements = getFocusableElements(container)
    if (elements.length > 0) {
      focus(elements[0])
    }
  }

  /**
   * Focus the last focusable element in a container
   */
  function focusLast(container: HTMLElement): void {
    const elements = getFocusableElements(container)
    if (elements.length > 0) {
      focus(elements[elements.length - 1])
    }
  }

  /**
   * Focus the next focusable element after the currently focused element
   */
  function focusNext(container: HTMLElement, wrap = true): void {
    const elements = getFocusableElements(container)
    if (elements.length === 0) return

    const currentIndex = elements.findIndex(el => el === document.activeElement)

    if (currentIndex !== -1) {
      const nextIndex = currentIndex + 1
      if (nextIndex < elements.length) {
        focus(elements[nextIndex])
      } else if (wrap) {
        // Wrap to first element
        focus(elements[0])
      }
    } else {
      // No element is focused, focus the first one
      focusFirst(container)
    }
  }

  /**
   * Focus the previous focusable element before the currently focused element
   */
  function focusPrevious(container: HTMLElement, wrap = true): void {
    const elements = getFocusableElements(container)
    if (elements.length === 0) return

    const currentIndex = elements.findIndex(el => el === document.activeElement)

    if (currentIndex !== -1) {
      const prevIndex = currentIndex - 1
      if (prevIndex >= 0) {
        focus(elements[prevIndex])
      } else if (wrap) {
        // Wrap to last element
        focus(elements[elements.length - 1])
      }
    } else {
      // No element is focused, focus the first one
      focusFirst(container)
    }
  }

  /**
   * Save the currently focused element
   */
  function saveFocus(): void {
    lastFocusedElement.value = document.activeElement as HTMLElement
  }

  /**
   * Restore focus to the previously saved element
   */
  function restoreFocus(): void {
    if (
      lastFocusedElement.value &&
      typeof lastFocusedElement.value.focus === 'function'
    ) {
      focus(lastFocusedElement.value)
    }
  }

  /**
   * Create keyboard handlers for managing focus with arrow keys
   */
  function createArrowKeyHandlers(container: HTMLElement, wrap = true) {
    return {
      handleLeftArrow: () => focusPrevious(container, wrap),
      handleRightArrow: () => focusNext(container, wrap),
      handleUpArrow: () => focusPrevious(container, wrap),
      handleDownArrow: () => focusNext(container, wrap)
    }
  }

  return {
    focus,
    focusFirst,
    focusLast,
    focusNext,
    focusPrevious,
    getFocusableElements,
    saveFocus,
    restoreFocus,
    createArrowKeyHandlers
  }
}
