























































































import AccountSpinner from '~/components/shared/account/AccountSpinner.vue'
import { defineComponent } from '~/utils/nuxt3-migration'
import CInfoModal from '~/components/shared/configurable/info/CInfoModal.vue'

export default defineComponent({
  components: { CInfoModal, AccountSpinner },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    info: {
      type: String,
      required: false,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    },
    headerClass: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    wrapHeader: {
      type: Boolean,
      default: true
    },
    overflowHidden: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasTitle() {
      return Boolean(this.$slots.title || this.title)
    },
    hasSubTitle() {
      return Boolean(this.$slots.subtitle || this.subtitle)
    },
    hasInfo() {
      return Boolean(this.$slots.info || this.info)
    },
    hasHeader() {
      return this.hasTitle || this.hasSubTitle
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {}
})
