import { inject } from 'tsyringe'

import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { Geolocation } from '~/models/common/types'
import {
  ClosestPostcodeResult,
  PostcodeLocation,
  PostcodeSuggestion
} from '~/models/location/postcode'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class PostcodeService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  // This endpoint only works for GR locations
  getClosestPostcode(geolocation: Geolocation): Promise<ClosestPostcodeResult> {
    const { lat, lon } = geolocation
    return this.requestBuilder
      .request('get', '/api/postcodes/closest/')
      .params({ lat, lon })
      .send()
  }

  searchPostcode(query: string): Promise<PostcodeSuggestion[]> {
    return this.requestBuilder
      .request('get', '/api/postcodes/search/')
      .params({ q: query })
      .map(body => toCamelCase(body.data.postcodes))
      .send()
  }

  getPostcodeLocations(
    postcode: string
  ): Promise<{ locations: PostcodeLocation[] }> {
    return this.requestBuilder
      .request('get', `/api/postcodes/locations/`)
      .params({ postcode })
      .send()
  }
}
