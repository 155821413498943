







import { ciLocationPin } from '~/icons/source/solid/location-pin'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    address: {
      type: [String, null],
      default: null,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup() {
    return { ciLocationPin }
  }
})
