import { useNamespacedStore } from '~/compositions/store'
import { STORAGE_NS, StorageState } from '~/store/modules/shared/storage/state'
import { useDep } from '~/compositions/dependency-container'
import { TEN_DAYS } from '~/constants/duration'
import CookiesService from '~/services/CookiesService'
import { Consent } from '~/models/common/types'

/**
 * The name of this cookie originates from car1
 * It needs to be the same here to avoid multiple verification attempts.
 *
 * TODO: remove this cookie entirely when it's no longer needed
 */
const OVER_18_CONSENT_COOKIE_NAME = 'ageverification'

export function useConsent() {
  const { dispatch: storageDispatch } = useNamespacedStore<StorageState>(
    STORAGE_NS
  )
  const cookiesService = useDep(CookiesService)

  async function setStorageConsent(name: Consent['name'], value: boolean) {
    await storageDispatch('set', {
      consent: {
        [name]: value
      }
    })
  }

  async function confirmOver18() {
    await setStorageConsent('over18', true)
    cookiesService.set(OVER_18_CONSENT_COOKIE_NAME, '1', {
      maxAge: TEN_DAYS
    })
  }

  async function confirmUnder18() {
    await setStorageConsent('over18', false)
    cookiesService.delete(OVER_18_CONSENT_COOKIE_NAME)
  }

  return { confirmOver18, confirmUnder18 }
}
