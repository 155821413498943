var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative"},[_c('button',_vm._b({staticClass:"tw-size-10 tw-rounded-lg tw-ring-1 tw-ring-blue-500 tw-text-blue-500 hover:tw-text-white tw-transition-colors tw-flex tw-items-center tw-justify-center",class:[
      _vm.isActive
        ? 'tw-bg-blue-500 hover:tw-bg-blue-700 hover:tw-ring-blue-700 tw-text-white'
        : 'hover:tw-bg-blue-200 hover:tw-ring-blue-200',
      {
        'tw-opacity-70': _vm.loading
      }
    ],attrs:{"disabled":_vm.loading,"title":_vm.buttonTitle},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClick($event)}}},'button',_vm.$attrs,false),[(_vm.loading)?_c('CSpinner',{attrs:{"size":"xs","variant":_vm.isActive ? 'white' : 'secondary'}}):_c('CIcon',{attrs:{"icon":_vm.ciCompare}})],1),_vm._v(" "),(_vm.isPc && _vm.isPopoverVisible)?_c('CDropdown',{staticClass:"tw-border-0",attrs:{"shown":_vm.isPopoverVisible,"triggers":['click'],"delay":"0","distance":50,"placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.isActive ? _vm.$t('added to') : ((_vm.$t('removed from')) + " "))+"\n      "),_c('CLink',{staticClass:"comparison-link",attrs:{"to":_vm.hasCompareUrlIds ? _vm.compareUrl : null}},[_c('span',{class:_vm.hasCompareUrlIds ? 'tw-underline' : 'tw-text-grey-900'},[_vm._v("\n          "+_vm._s(_vm.$t('comparison').toLowerCase())+"\n        ")])])]},proxy:true}],null,false,2526825483)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }