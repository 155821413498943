var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lg:tw-rounded-xl tw-rounded-lg lg:tw-p-6 tw-p-4 tw-pt-3 tw-bg-white tw-shadow  tw-flex tw-flex-col lg:tw-gap-6 tw-gap-4",class:{ 'tw-overflow-hidden': _vm.overflowHidden }},[(_vm.$slots.above)?_c('div',[_vm._t("above")],2):_vm._e(),_vm._v(" "),(_vm.hasHeader || _vm.$slots.action)?_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-4",class:{ 'max-md:tw-flex-col': _vm.wrapHeader }},[(_vm.hasHeader)?_c('div',{staticClass:"tw-text-left tw-flex tw-flex-col tw-gap-2 tw-w-full",class:_vm.headerClass},[(_vm.hasTitle)?_c('div',{staticClass:"tw-text-lg tw-font-semibold tw-text-grey-900 tw-flex tw-justify-between tw-items-center tw-gap-2"},[(_vm.$slots.title)?_vm._t("title"):(_vm.title)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),(_vm.$slots.action && !_vm.wrapHeader && (_vm.hasSubTitle || _vm.hasInfo))?_c('span',{staticClass:"max-md:tw-mt-1 tw-self-start"},[_vm._t("action")],2):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.hasSubTitle || _vm.hasInfo)?_c('div',{class:{ 'tw--mt-1': _vm.hasSubTitle && _vm.hasInfo }},[(_vm.hasSubTitle)?_c('span',{class:{ 'tw-mr-1': _vm.hasInfo }},[(_vm.$slots.subtitle)?_vm._t("subtitle"):(_vm.subtitle)?_c('span',[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()],2):_vm._e(),(_vm.$slots.info || _vm.info)?_c('CInfoModal',{class:{ 'tw-relative tw-bottom-[-0.4rem]': _vm.hasSubTitle },attrs:{"title":_vm.title || _vm.$t('information'),"size":20,"force-blue":""}},[(_vm.$slots.info)?_vm._t("info"):(_vm.info)?_c('span',[_vm._v(_vm._s(_vm.info))]):_vm._e()],2):_vm._e()],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.$slots.action && (_vm.wrapHeader || !(_vm.hasSubTitle || _vm.hasInfo)))?_c('span',{class:{
        'md:tw-ml-auto [&>*]:max-md:tw-w-full max-md:tw-self-stretch': _vm.wrapHeader,
        'max-md:tw-mt-1': !_vm.wrapHeader
      }},[_vm._t("action")],2):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"tw-min-h-[100px]",class:[
      _vm.contentClass,
      {
        'title-no-sub': _vm.hasTitle && !_vm.hasSubTitle,
        'tw-min-h-0': _vm.currentRouteName === '__account_subscriptions'
      }
    ]},[_vm._t("default")],2):_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-h-[200px]"},[_c('AccountSpinner')],1),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"tw-flex tw-bg-white tw-justify-between max-md:tw-flex-col-reverse tw-gap-2 [&>*]:max-md:tw-w-full [&>*:last-child]:tw-ml-auto"},[_vm._t("footer")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }