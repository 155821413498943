import { CIcon } from '~/icons/types'

export const ciEnvelope: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M10.4 14.2 1.5 8.7v8.6c0 1.7 1.3 3 3 3h15c1.7 0 3-1.3 3-3V8.7l-8.9 5.5c-1 .6-2.2.6-3.2 0Z'
    },
    {
      d:
        'M19.5 3.8h-15c-1.7 0-3 1.3-3 3V7l9.7 6c.5.3 1.1.3 1.6 0l9.7-6v-.2c0-1.7-1.3-3-3-3Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'envelope',
  type: 'solid'
}
