















































import { STORAGE_NS, StorageState } from '~/store/modules/shared/storage/state'
import CompareService from '~/services/compare/CompareService'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  PropType,
  ref,
  useRouter,
  nextTick
} from '~/utils/nuxt3-migration'
import { Timeout } from '~/models/timing/timeout'
import { useUserAgent } from '~/compositions/user-agent'
import { useNamespacedStore } from '~/compositions/store'
import { CategoryId } from '~/models/category/types'
import { Classified } from '~/models/classified/types'
import { useI18n } from '~/compositions/i18n'
import { useDep } from '~/compositions/dependency-container'
import { useSnackbar } from '~/compositions/snackbar'
import { ciCompare } from '~/icons/source/solid/compare'

export default defineComponent({
  props: {
    categoryId: {
      type: Number as PropType<CategoryId>,
      default: null
    },
    classifiedId: {
      type: Number as PropType<Classified['id']>,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    let popoverTimeout: Timeout
    const isPopoverVisible = ref(false)
    const loading = ref(false)

    const { isPc } = useUserAgent()
    const snackbar = useSnackbar()
    const { t } = useI18n()
    const router = useRouter()
    const compareService = useDep(CompareService)
    const { state: storageState } = useNamespacedStore<StorageState>(STORAGE_NS)

    onBeforeUnmount(() => {
      if (popoverTimeout) {
        clearTimeout(popoverTimeout)
      }
    })

    const compareStorageItem = computed(() => storageState.state.compare)

    const isActive = computed(() => {
      if (!props.categoryId || !props.classifiedId) {
        return props.active
      }

      const compareStorageCategoryItem =
        compareStorageItem.value && compareStorageItem.value[props.categoryId]

      if (
        !compareStorageCategoryItem ||
        !Array.isArray(compareStorageCategoryItem)
      ) {
        return false
      }

      return compareStorageCategoryItem.includes(props.classifiedId)
    })

    const buttonTitle = computed(() =>
      isActive.value ? t('remove from comparison') : t('add to comparison list')
    )

    const compareUrl = computed(() =>
      compareService.createCompareUrl(props.categoryId)
    )

    const hasCompareUrlIds = computed(() => compareUrl.value.match(/id/))

    async function onClick(e: Event) {
      emit('click', e)

      if (!props.categoryId || !props.classifiedId) {
        return
      }

      loading.value = true

      try {
        await compareService.setComparedClassified(
          props.categoryId,
          props.classifiedId
        )

        if (isPc.value) {
          if (popoverTimeout) {
            clearTimeout(popoverTimeout)
          }

          isPopoverVisible.value = false
          await nextTick()
          isPopoverVisible.value = true

          popoverTimeout = setTimeout(() => {
            isPopoverVisible.value = false
          }, 3000)
        } else {
          snackbar.success(isActive.value ? t('added to') : t('removed from'), {
            timeout: 1800,
            classes: ['action-button-as-text', 'above-floating-button'],
            action: {
              text: t('comparison') as string,
              callback: () =>
                router.push({
                  path: compareUrl.value
                })
            }
          })
        }
      } finally {
        loading.value = false
      }
    }

    return {
      ciCompare,
      compareStorageItem,
      isActive,
      isPc,
      buttonTitle,
      isPopoverVisible,
      compareUrl,
      hasCompareUrlIds,
      loading,
      onClick
    }
  }
})
