import { CIcon } from '~/icons/types'

export const ciAngleDown: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 16.5a1.5 1.5 0 0 1-1.06-.44l-5-5a1.5 1.5 0 0 1 2.12-2.12L12 12.878l3.94-3.94a1.5 1.5 0 0 1 2.12 2.122l-5 5a1.5 1.5 0 0 1-1.06.44Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'angle-down',
  type: 'solid'
}
