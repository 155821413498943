





















































import { PARKING_NS, ParkingState } from '~/store/modules/shared/parking/state'
import StatsService from '~/services/stats/StatsService'
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  ref,
  useRoute,
  useRouter,
  watch
} from '~/utils/nuxt3-migration'
import { useDeps } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { useLogger } from '~/compositions/logger'
import { useUserAgent } from '~/compositions/user-agent'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { ClassifiedsFavoritesService } from '~/services/ClassifiedsFavoritesService'
import { Timeout } from '~/models/timing/timeout'
import { ciParkingP } from '~/icons/source/solid/parking-p'
import { SET_HAS_PARKED } from '~/store/modules/shared/user/mutation-types'

export default defineComponent({
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    activeLabel: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    classifiedId: {
      type: [Number, String],
      required: true
    },
    parked: {
      type: Boolean,
      required: true
    },
    showNotification: {
      type: Boolean,
      required: false,
      default: true
    },
    floating: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCustomClickHandler: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const [classifiedsFavoritesService, statsService] = useDeps(
      ClassifiedsFavoritesService,
      StatsService
    )
    const snackbar = useSnackbar()
    const { commit: userCommit } = useNamespacedStore<UserState>(USER_NS)
    const {
      getters: parkingGetters,
      dispatch: parkingDispatch,
      commit: parkingCommit
    } = useNamespacedStore<ParkingState>(PARKING_NS)
    const { t } = useI18n()
    const logger = useLogger()
    const route = useRoute()
    const router = useRouter()
    const { isPc, isMobile } = useUserAgent()

    const isSaved = ref(props.parked)
    const loading = ref(false)
    const timeout = ref<Timeout | null>()
    const isPopoverVisible = ref(false)

    watch(
      () => props.parked,
      newVal => {
        isSaved.value = newVal
      }
    )

    onBeforeUnmount(() => {
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
    })

    const parkingShown = computed(() => parkingGetters('getShowParking'))

    const activeTab = computed(() => parkingGetters('getActiveTab'))

    const displayText = computed(() => {
      const activeLabel = props.activeLabel || t('parked')
      const label = props.label || t('park it')

      return isSaved.value ? activeLabel : label
    })

    function onButtonClick() {
      if (props.hasCustomClickHandler) {
        emit('parked', !isSaved.value)
        return
      }

      toggleFavorite()
    }

    async function displayPopover() {
      if (timeout.value) {
        clearTimeout(timeout.value)
        isPopoverVisible.value = false
      }

      await nextTick()

      timeout.value = setTimeout(() => {
        isPopoverVisible.value = false
      }, 3000)
      isPopoverVisible.value = true
    }

    async function toggleFavorite() {
      loading.value = true

      if (isSaved.value) {
        await removeFromFavorites()
      } else {
        await addToFavorites()
      }

      loading.value = false
    }

    async function addToFavorites() {
      try {
        await classifiedsFavoritesService.addClassifiedToFavorites(
          props.classifiedId
        )

        handleSuccess(true)

        userCommit(SET_HAS_PARKED, true)

        statsService
          .record('events.clsfds.parked', [props.classifiedId])
          .catch(() => {})
      } catch (error) {
        handleError(error)
      }
    }

    async function removeFromFavorites() {
      try {
        await classifiedsFavoritesService.removeClassifiedFromFavorites(
          props.classifiedId
        )

        handleSuccess(false)
      } catch (error) {
        handleError(error)
      }
    }

    function handleSuccess(isParked: boolean) {
      displayPopover()

      if (props.showNotification) {
        showSuccessNotification(isParked)
      }

      emit('parked', isParked)

      // parking modal shown or we are on full parking
      if (
        parkingShown.value ||
        route.value?.name?.startsWith('__account_parking') ||
        route.value?.name?.startsWith('__parking')
      ) {
        if (activeTab.value === 'classifieds') {
          parkingDispatch('fetch_classifieds_list')
        } else if (activeTab.value === 'recent') {
          parkingCommit('TOGGLE_RECENT_PARKED', {
            classifiedId: props.classifiedId,
            isParked
          })
        }
      }
    }

    function handleError(error: any) {
      const response = error.response
      if (!response) {
        snackbar.error(t('an error occurred please try again later'))
        logger.captureError(error)

        return
      }

      snackbar.error(response.data.error)
    }

    function showSuccessNotification(isParked: boolean) {
      if (isPc.value) {
        return
      }

      snackbar.success(
        isParked ? t('added to::parking_btn') : t('removed from::parking_btn'),
        {
          classes: [
            'action-button-as-text',
            'above-floating-button',
            '!tw-bg-blue-700'
          ],
          time: 1800,
          action: {
            text: t('parked::parking_btn'),
            callback: () =>
              router.push({
                path: '/parking'
              })
          }
        }
      )
    }

    return {
      ciParkingP,
      onButtonClick,
      removeFromFavorites,
      addToFavorites,
      isSaved,
      isPopoverVisible,
      isPc,
      isMobile,
      displayText,
      loading
    }
  }
})
