


































































import { defineComponent, PropType, ref } from '~/utils/nuxt3-migration'
import { HeaderDropdown } from '~/models/header/header-dropdown'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'
import CMenuItem from '~/components/shared/configurable/menu/CMenuItem.vue'
import useFocus from '~/compositions/useFocus'

export default defineComponent({
  inheritAttrs: true,
  components: {
    CMenuItem,
    CNewBadge
  },
  props: {
    dropdown: {
      type: Object as PropType<HeaderDropdown>,
      required: true
    }
  },
  setup(_, { emit }) {
    const { focusFirst, focusLast, focusNext, focusPrevious } = useFocus()

    const shown = ref(false)
    const triggerRef = ref<HTMLElement | null>(null)
    const menuRef = ref<HTMLElement | null>(null)

    function enter() {
      shown.value = true
    }
    function leave() {
      shown.value = false
    }
    function toggleDropdown() {
      shown.value = !shown.value
      if (shown.value && menuRef.value) {
        focusFirst(menuRef.value)
      }
    }
    function handleKeyDown(e: KeyboardEvent) {
      // Early return if dropdown is closed and key isn't a trigger key
      if (!shown.value && !['Enter', ' '].includes(e.key)) return

      // Handle trigger button actions
      if (e.target === triggerRef.value) {
        if (['Enter', ' '].includes(e.key)) {
          e.preventDefault()
          toggleDropdown()

          return
        }
        if (e.key === 'ArrowDown' && shown.value) {
          e.preventDefault()
          if (menuRef.value) {
            focusFirst(menuRef.value)
          }

          return
        }
      }

      // Handle menu navigation
      if (shown.value && menuRef.value) {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault()
            focusNext(menuRef.value)
            break
          case 'ArrowUp':
            e.preventDefault()
            focusPrevious(menuRef.value)
            break
          case 'Home':
            e.preventDefault()
            focusFirst(menuRef.value)
            break
          case 'End':
            e.preventDefault()
            focusLast(menuRef.value)
            break
          case 'Escape':
            e.preventDefault()
            leave()
            if (triggerRef.value) triggerRef.value.focus()
            break
          case 'Tab':
            // Only prevent default if we're tabbing forward from trigger
            if (!e.shiftKey && e.target === triggerRef.value) {
              e.preventDefault()
              focusFirst(menuRef.value)
            }
            break
        }
      }
    }
    function handleItemClick(link: any, event: Event) {
      emit('click', { link, event })
      leave()
      if (triggerRef.value) triggerRef.value.focus()
    }

    return {
      shown,
      triggerRef,
      menuRef,
      enter,
      leave,
      toggleDropdown,
      handleKeyDown,
      handleItemClick
    }
  }
})
