















import { defineComponent } from '~/utils/nuxt3-migration'
import { ciChevronRight } from '~/icons/source/solid/chevron-right'

export default defineComponent({
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  setup() {
    return { ciChevronRight }
  }
})
