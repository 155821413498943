















import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  useRoute
} from '~/utils/nuxt3-migration'
import RogueClassifiedsContainer from '~/components/car/cpanel/RogueClassifiedsContainer.vue'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { HIDE_TRANSFER_CLASSIFIEDS_MODAL } from '~/constants/classified/tranfer-classifieds'
import { useCookies } from '~/compositions/useCookies'
import { ONE_MONTH } from '~/constants/duration'

export default defineComponent({
  components: { RogueClassifiedsContainer },
  setup() {
    const showRoguesModal = ref(false)
    const { state: userState } = useNamespacedStore<UserState>(USER_NS)

    const cookies = useCookies()
    const route = useRoute()

    const rogueClassifiedsCount = computed(
      () => userState.rogueClassifiedsCount
    )
    const setHideCookie = () => {
      cookies.set(HIDE_TRANSFER_CLASSIFIEDS_MODAL, '1', {
        maxAge: ONE_MONTH
      })
    }
    const closeModal = () => {
      setHideCookie()
      showRoguesModal.value = false
    }

    onMounted(() => {
      if (
        rogueClassifiedsCount.value &&
        !hasCookieOrSessionItem() &&
        !route.value.name?.startsWith('__account_rogue_classifieds')
      ) {
        showRoguesModal.value = true
      }
    })

    watch(rogueClassifiedsCount, (val, oldVal) => {
      const oldValNumber = oldVal || 0
      if (
        val > 0 &&
        val > oldValNumber &&
        !hasCookieOrSessionItem() &&
        !route.value.name?.startsWith('__account_rogue_classifieds')
      ) {
        showRoguesModal.value = true
      }
    })

    const hasCookieOrSessionItem = (): boolean => {
      // also checking for the session storage item here cause it was the previous way of saving this
      const rogueClassifiedsStorageItem = sessionStorage.getItem(
        'hideTranferClassifiedsModal' // typo existed before so dont fix it
      )

      const rogueClassifiedsCookie = cookies.get(
        HIDE_TRANSFER_CLASSIFIEDS_MODAL
      )

      return (
        Boolean(rogueClassifiedsStorageItem) || Boolean(rogueClassifiedsCookie)
      )
    }

    return {
      rogueClassifiedsCount,
      showRoguesModal,
      setHideCookie,
      closeModal
    }
  }
})
